<template>
	<div class="poldeal">
		<probm :probm="proplem"></probm>
	</div>
</template>

<script>
	import probm from "@/components/probm/probm"
	import qs from "qs"
	import moment from "moment"
	// 局部引入
	import Clipboard from "clipboard"
	export default {
		data() {
			return {
				proplem: {}
			}
		},
		components: {
			probm
		},
		created() {
			let id = this.$route.query.id
			this.InvestmentDetails(id)
		},
		methods: {
			// 获取详情页
			InvestmentDetails(id) {
				let url = this.$api.policy.getdetails
				this.$axios.post(
					url,
					qs.stringify({
						deta_id: id
					})
				).then(res => {
					if (res.code) {
						res.data.data[0].inputtime = moment(parseInt(res.data.data[0].inputtime * 1000)).format(
							"YYYY-MM-DD")
						this.proplem = res.data.data[0]
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .time p:last-child {
		margin-left: 0;
	}
	
	.poldeal {
		padding: 0 30px 70px 30px;
	}
	
	.share {
		display: flex;
		align-items: center;
		color: rgba(102, 102, 102, 1);
		font-size: 16px;
		font-weight: 500;
		justify-content: flex-end;
		border-top: 0.5px solid rgba(235, 236, 239, 1);
		padding-top: 30px;

		.sh-icon {
			display: flex;

			p {
				width: 32px;
				height: 32px;
				background: rgba(235, 236, 239, 1);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 12px;
				cursor: pointer;
				&:last-child {
					margin-right: 0;
				}

				i {
					font-size: 24px;
				}
			}
		}
	}

	@media (max-width:768px) {
		.share {
			font-size: 14px;
			font-weight: 300;
		}
		.poldeal {
			padding: 0 0 20px 0;
		}
	}
</style>
